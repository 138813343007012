import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Section } from 'components';
import { EventList } from 'containers';

const EventAllPage = () => {
  const data = useStaticQuery(query);
  return (
    <Layout>
      <div className='uk-padding-large'></div>
      <Section title='모든 이벤트'>
        <EventList eventLocations={data.allStrapiLocations.edges} />
      </Section>
    </Layout>
  );
};

const query = graphql`
  query {
    allStrapiLocations {
      edges {
        node {
          title
        }
      }
    }
  }
`;

export default EventAllPage;
